/* You can add global styles to this file, and also import other style files */
// regular style toast
@import 'ngx-toastr/toastr';

// if you'd like to use it without importing all of bootstrap it requires
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

// boostrap 5
@import 'ngx-toastr/toastr-bs5-alert';
@import "~simple-datatables/dist/style.css";

/* Extra small devices (phones, less than 768px) */
@media (max-width:575) {
    body {
        font-size: 8px;
    }
}

/* Small devices (phones, less than 768px) */
@media (min-width:576px) {
    body {
        font-size: 10px;
    }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width:768px) {
    body {
        font-size: 16px;
    }
}

/* Large devices (desktops, 992px and up) */
@media (min-width:992px) {
    body {
        font-size: 16px;
    }
}

/* Extra Large devices (large desktops, 1200px and up) */
@media (min-width:1200px) {
    body {
        font-size: 18px;
    }
}

:root {
    --brand-theme: #F26327;
    --brand-primary: #dc1414;
    --brand-theme-lighter: #FFC70F;
    --brand-accent-1: #9B9B9B;
    --brand-accent-2: #C6C2C2;
    --brand-info-client: #258ed0;
    --brand-warning: #ff6600;
    --brand-hover: #DDE6E1;
}

html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-family: "Open Sans", system-ui, -apple-system, BlinkMacSystemFont, Roboto, Helvetica, Arial, sans-serif;
}

.xxl-size-text {
    font-size: 44px;
    height: 56px;
}

.xl-size-text {
    font-size: 32px;
    height: 36px;
}

.lg-size-text {
    font-size: 24px;
    height: 28px;
}

.md-size-text {
    font-size: 19px;
    height: 24px;
}

.base-size-text {
    font-size: 16px;
    height: 21px;
}

.sm-size-text {
    font-size: 14px;
    height: 18px;
}

.password-eye {
    display: flex;
    align-items: center;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: center;
    white-space: nowrap;
    border-right: 1px solid #ced4da;
    border-top: 1px solid #ced4da;
    border-bottom: 1px solid #ced4da;
    border-radius: 0.375rem;
}

.error-message {
    color: var(--brand-primary);
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

// Date Picker
.dp-hidden {
    width: 0;
    margin: 0;
    border: none;
    padding: 0;
}

.custom-day {
    text-align: center;
    padding: 0.185rem 0.25rem;
    display: inline-block;
    height: 2rem;
    width: 2rem;
}

.custom-day.focused {
    background-color: #e6e6e6;
}

.custom-day.range, .custom-day:hover {
    background-color: var(--brand-theme);
    color: white;
}

.custom-day.faded {
    background-color: var(--brand-theme);
    opacity: 0.5;
}

::ng-deep .bg-primary {
    background-color: var(--brand-theme) !important;
}